import Vue from 'vue'
import Router from 'vue-router'
import { normalizeURL, decode } from 'ufo'
import { interopDefault } from './utils'
import scrollBehavior from './router.scrollBehavior.js'

const _1d42d0c6 = () => interopDefault(import('../pages/account.vue' /* webpackChunkName: "pages/account" */))
const _6a8eae4c = () => interopDefault(import('../pages/account/general-info.vue' /* webpackChunkName: "pages/account/general-info" */))
const _e9cdc304 = () => interopDefault(import('../pages/account/orders.vue' /* webpackChunkName: "pages/account/orders" */))
const _c2c5dc56 = () => interopDefault(import('../pages/account/referral-info.vue' /* webpackChunkName: "pages/account/referral-info" */))
const _97c30632 = () => interopDefault(import('../pages/account/user-info.vue' /* webpackChunkName: "pages/account/user-info" */))
const _b84e6b06 = () => interopDefault(import('../pages/checkout.vue' /* webpackChunkName: "pages/checkout" */))
const _6afe784f = () => interopDefault(import('../pages/checkout/index.js' /* webpackChunkName: "pages/checkout/index" */))
const _03169215 = () => interopDefault(import('../pages/checkout/order-summary.vue' /* webpackChunkName: "pages/checkout/order-summary" */))
const _d2a39e18 = () => interopDefault(import('../pages/checkout/payment.vue' /* webpackChunkName: "pages/checkout/payment" */))
const _1a88ad37 = () => interopDefault(import('../pages/checkout/personal-details.vue' /* webpackChunkName: "pages/checkout/personal-details" */))
const _3f868984 = () => interopDefault(import('../pages/event-list-page.vue' /* webpackChunkName: "pages/event-list-page" */))
const _1fc8c4a2 = () => interopDefault(import('../pages/faq.vue' /* webpackChunkName: "pages/faq" */))
const _0c15c5d0 = () => interopDefault(import('../pages/impressum.vue' /* webpackChunkName: "pages/impressum" */))
const _80bc9d76 = () => interopDefault(import('../pages/login/index.vue' /* webpackChunkName: "pages/login/index" */))
const _6a9951cb = () => interopDefault(import('../pages/magazine.vue' /* webpackChunkName: "pages/magazine" */))
const _6d672d32 = () => interopDefault(import('../pages/magazine/article.vue' /* webpackChunkName: "pages/magazine/article" */))
const _7306bc72 = () => interopDefault(import('../pages/magazine/category.vue' /* webpackChunkName: "pages/magazine/category" */))
const _457b14ce = () => interopDefault(import('../pages/magazine/magazine-wrapper.vue' /* webpackChunkName: "pages/magazine/magazine-wrapper" */))
const _a4d5ca46 = () => interopDefault(import('../pages/order-success.vue' /* webpackChunkName: "pages/order-success" */))
const _612837a4 = () => interopDefault(import('../pages/partner/index.vue' /* webpackChunkName: "pages/partner/index" */))
const _bf0c5bd8 = () => interopDefault(import('../pages/password-reset.vue' /* webpackChunkName: "pages/password-reset" */))
const _61b66e9d = () => interopDefault(import('../pages/ping-default.vue' /* webpackChunkName: "pages/ping-default" */))
const _2d7d84fa = () => interopDefault(import('../pages/ping-optimized.vue' /* webpackChunkName: "pages/ping-optimized" */))
const _718c9fae = () => interopDefault(import('../pages/ping.vue' /* webpackChunkName: "pages/ping" */))
const _f68fd6d4 = () => interopDefault(import('../pages/root-domain/home.vue' /* webpackChunkName: "" */))
const _774c84c2 = () => interopDefault(import('../pages/search.vue' /* webpackChunkName: "pages/search" */))
const _5b366f4e = () => interopDefault(import('../pages/sitemap.vue' /* webpackChunkName: "pages/sitemap" */))
const _05df95ca = () => interopDefault(import('../pages/stripe-3d-auth-complete.vue' /* webpackChunkName: "pages/stripe-3d-auth-complete" */))
const _1e5868d6 = () => interopDefault(import('../pages/sub-domain/home.vue' /* webpackChunkName: "" */))
const _2994dab9 = () => interopDefault(import('../pages/teamevent/index.vue' /* webpackChunkName: "pages/teamevent/index" */))
const _2bd3f12b = () => interopDefault(import('../pages/terms-and-conditions.vue' /* webpackChunkName: "pages/terms-and-conditions" */))
const _6b129f49 = () => interopDefault(import('../pages/terms-supplier.vue' /* webpackChunkName: "pages/terms-supplier" */))
const _8097ec2e = () => interopDefault(import('../pages/auth/social-login.vue' /* webpackChunkName: "pages/auth/social-login" */))
const _05f7648a = () => interopDefault(import('../pages/city-category/city-category.vue' /* webpackChunkName: "pages/city-category/city-category" */))
const _2061ad0b = () => interopDefault(import('../pages/embedded/booking.vue' /* webpackChunkName: "pages/embedded/booking" */))
const _473feef0 = () => interopDefault(import('../pages/embedded/button.vue' /* webpackChunkName: "pages/embedded/button" */))
const _ad015990 = () => interopDefault(import('../pages/login/forgot-password.vue' /* webpackChunkName: "pages/login/forgot-password" */))
const _0049130b = () => interopDefault(import('../pages/partner/booking-solution.vue' /* webpackChunkName: "pages/partner/booking-solution" */))
const _03b8ae18 = () => interopDefault(import('../pages/partner/pricing.vue' /* webpackChunkName: "pages/partner/pricing" */))
const _2d67ebf7 = () => interopDefault(import('../pages/root-domain/giftcard.vue' /* webpackChunkName: "pages/root-domain/giftcard" */))
const _3acdeb08 = () => interopDefault(import('../pages/special-giftcard/special-giftcard.vue' /* webpackChunkName: "pages/special-giftcard/special-giftcard" */))
const _cb77bf92 = () => interopDefault(import('../pages/sub-domain/giftcard.vue' /* webpackChunkName: "pages/sub-domain/giftcard" */))
const _0b4974c4 = () => interopDefault(import('../pages/t/general-event-list-page.vue' /* webpackChunkName: "pages/t/general-event-list-page" */))
const _504ba815 = () => interopDefault(import('../pages/c/_categorySlug.vue' /* webpackChunkName: "pages/c/_categorySlug" */))
const _c6acd3c6 = () => interopDefault(import('../pages/city/_city.vue' /* webpackChunkName: "pages/city/_city" */))
const _54dd303c = () => interopDefault(import('../pages/e/_event.vue' /* webpackChunkName: "pages/e/_event" */))
const _5bded326 = () => interopDefault(import('../pages/e/_event/index.ts' /* webpackChunkName: "pages/e/_event/index" */))
const _4bb491f7 = () => interopDefault(import('../pages/e/_event/BasicDetails.vue' /* webpackChunkName: "pages/e/_event/BasicDetails" */))
const _49dc5f19 = () => interopDefault(import('../pages/e/_event/EventBreadcrumbs.vue' /* webpackChunkName: "pages/e/_event/EventBreadcrumbs" */))
const _53193dbe = () => interopDefault(import('../pages/e/_event/EventIconList.vue' /* webpackChunkName: "pages/e/_event/EventIconList" */))
const _09e4a31e = () => interopDefault(import('../pages/e/_event/EventIconList/EventIconListTable.vue' /* webpackChunkName: "pages/e/_event/EventIconList/EventIconListTable" */))
const _3caa6242 = () => interopDefault(import('../pages/e/_event/Gallery.vue' /* webpackChunkName: "pages/e/_event/Gallery" */))
const _419eca1d = () => interopDefault(import('../pages/e/_event/LocationAndMap.vue' /* webpackChunkName: "pages/e/_event/LocationAndMap" */))
const _641649e8 = () => interopDefault(import('../pages/e/_event/PrivateEventInfo.vue' /* webpackChunkName: "pages/e/_event/PrivateEventInfo" */))
const _9d6d40b0 = () => interopDefault(import('../pages/e/_event/ReviewShowcase.vue' /* webpackChunkName: "pages/e/_event/ReviewShowcase" */))
const _449a4604 = () => interopDefault(import('../pages/e/_event/composables/useEventBreadcrumbs.ts' /* webpackChunkName: "pages/e/_event/composables/useEventBreadcrumbs" */))
const _182f4d3c = () => interopDefault(import('../pages/e/_event/composables/useKftBuyBox.ts' /* webpackChunkName: "pages/e/_event/composables/useKftBuyBox" */))
const _8885c8bc = () => interopDefault(import('../pages/p/_permalink.vue' /* webpackChunkName: "pages/p/_permalink" */))
const _76ef3d98 = () => interopDefault(import('../pages/preview/_previewPermalink.vue' /* webpackChunkName: "pages/preview/_previewPermalink" */))
const _6b77638b = () => interopDefault(import('../pages/review/_event.vue' /* webpackChunkName: "pages/review/_event" */))
const _0a618831 = () => interopDefault(import('../pages/teamevent/_city.vue' /* webpackChunkName: "pages/teamevent/_city" */))

const emptyFn = () => {}

Vue.use(Router)

export const routerOptions = {
  mode: 'history',
  base: '/',
  linkActiveClass: 'nuxt-link-active',
  linkExactActiveClass: 'nuxt-link-exact-active',
  scrollBehavior,

  routes: [{
    path: "/de-de/account/",
    component: _1d42d0c6,
    pathToRegexpOptions: {"strict":true},
    name: "account___de-de",
    children: [{
      path: "general-info/",
      component: _6a8eae4c,
      pathToRegexpOptions: {"strict":true},
      name: "account-general-info___de-de"
    }, {
      path: "orders/",
      component: _e9cdc304,
      pathToRegexpOptions: {"strict":true},
      name: "account-orders___de-de"
    }, {
      path: "referral-info/",
      component: _c2c5dc56,
      pathToRegexpOptions: {"strict":true},
      name: "account-referral-info___de-de"
    }, {
      path: "user-info/",
      component: _97c30632,
      pathToRegexpOptions: {"strict":true},
      name: "account-user-info___de-de"
    }]
  }, {
    path: "/de-de/checkout/",
    component: _b84e6b06,
    pathToRegexpOptions: {"strict":true},
    children: [{
      path: "",
      component: _6afe784f,
      pathToRegexpOptions: {"strict":true},
      name: "checkout___de-de"
    }, {
      path: "order-summary/",
      component: _03169215,
      pathToRegexpOptions: {"strict":true},
      name: "checkout-order-summary___de-de"
    }, {
      path: "payment/",
      component: _d2a39e18,
      pathToRegexpOptions: {"strict":true},
      name: "checkout-payment___de-de"
    }, {
      path: "personal-details/",
      component: _1a88ad37,
      pathToRegexpOptions: {"strict":true},
      name: "checkout-personal-details___de-de"
    }]
  }, {
    path: "/de-de/event-list-page/",
    component: _3f868984,
    pathToRegexpOptions: {"strict":true},
    name: "event-list-page___de-de"
  }, {
    path: "/de-de/faq/",
    component: _1fc8c4a2,
    pathToRegexpOptions: {"strict":true},
    name: "faq___de-de"
  }, {
    path: "/de-de/impressum/",
    component: _0c15c5d0,
    pathToRegexpOptions: {"strict":true},
    name: "impressum___de-de"
  }, {
    path: "/de-de/login/",
    component: _80bc9d76,
    pathToRegexpOptions: {"strict":true},
    name: "login___de-de"
  }, {
    path: "/de-de/magazine/",
    component: _6a9951cb,
    pathToRegexpOptions: {"strict":true},
    name: "magazine___de-de",
    children: [{
      path: "article/",
      component: _6d672d32,
      pathToRegexpOptions: {"strict":true},
      name: "magazine-article___de-de"
    }, {
      path: "category/",
      component: _7306bc72,
      pathToRegexpOptions: {"strict":true},
      name: "magazine-category___de-de"
    }, {
      path: "magazine-wrapper/",
      component: _457b14ce,
      pathToRegexpOptions: {"strict":true},
      name: "magazine-magazine-wrapper___de-de"
    }]
  }, {
    path: "/de-de/order-success/",
    component: _a4d5ca46,
    pathToRegexpOptions: {"strict":true},
    name: "order-success___de-de"
  }, {
    path: "/de-de/partner/",
    component: _612837a4,
    pathToRegexpOptions: {"strict":true},
    name: "partner___de-de"
  }, {
    path: "/de-de/password-reset/",
    component: _bf0c5bd8,
    pathToRegexpOptions: {"strict":true},
    name: "password-reset___de-de"
  }, {
    path: "/de-de/ping-default/",
    component: _61b66e9d,
    pathToRegexpOptions: {"strict":true},
    name: "ping-default___de-de"
  }, {
    path: "/de-de/ping-optimized/",
    component: _2d7d84fa,
    pathToRegexpOptions: {"strict":true},
    name: "ping-optimized___de-de"
  }, {
    path: "/de-de/ping/",
    component: _718c9fae,
    pathToRegexpOptions: {"strict":true},
    name: "ping___de-de"
  }, {
    path: "/de-de/root-domain/",
    component: _f68fd6d4,
    props: true,
    name: "home___de-de"
  }, {
    path: "/de-de/search/",
    component: _774c84c2,
    pathToRegexpOptions: {"strict":true},
    name: "search___de-de"
  }, {
    path: "/de-de/sitemap/",
    component: _5b366f4e,
    pathToRegexpOptions: {"strict":true},
    name: "sitemap___de-de"
  }, {
    path: "/de-de/stripe-3d-auth-complete/",
    component: _05df95ca,
    pathToRegexpOptions: {"strict":true},
    name: "stripe-3d-auth-complete___de-de"
  }, {
    path: "/de-de/sub-domain/",
    component: _1e5868d6,
    props: true,
    name: "supplier-home___de-de"
  }, {
    path: "/de-de/teamevent/",
    component: _2994dab9,
    pathToRegexpOptions: {"strict":true},
    name: "teamevent___de-de"
  }, {
    path: "/de-de/terms-and-conditions/",
    component: _2bd3f12b,
    pathToRegexpOptions: {"strict":true},
    name: "terms-and-conditions___de-de"
  }, {
    path: "/de-de/terms-supplier/",
    component: _6b129f49,
    pathToRegexpOptions: {"strict":true},
    name: "terms-supplier___de-de"
  }, {
    path: "/en-us/account/",
    component: _1d42d0c6,
    pathToRegexpOptions: {"strict":true},
    name: "account___en-us",
    children: [{
      path: "general-info/",
      component: _6a8eae4c,
      pathToRegexpOptions: {"strict":true},
      name: "account-general-info___en-us"
    }, {
      path: "orders/",
      component: _e9cdc304,
      pathToRegexpOptions: {"strict":true},
      name: "account-orders___en-us"
    }, {
      path: "referral-info/",
      component: _c2c5dc56,
      pathToRegexpOptions: {"strict":true},
      name: "account-referral-info___en-us"
    }, {
      path: "user-info/",
      component: _97c30632,
      pathToRegexpOptions: {"strict":true},
      name: "account-user-info___en-us"
    }]
  }, {
    path: "/en-us/checkout/",
    component: _b84e6b06,
    pathToRegexpOptions: {"strict":true},
    children: [{
      path: "",
      component: _6afe784f,
      pathToRegexpOptions: {"strict":true},
      name: "checkout___en-us"
    }, {
      path: "order-summary/",
      component: _03169215,
      pathToRegexpOptions: {"strict":true},
      name: "checkout-order-summary___en-us"
    }, {
      path: "payment/",
      component: _d2a39e18,
      pathToRegexpOptions: {"strict":true},
      name: "checkout-payment___en-us"
    }, {
      path: "personal-details/",
      component: _1a88ad37,
      pathToRegexpOptions: {"strict":true},
      name: "checkout-personal-details___en-us"
    }]
  }, {
    path: "/en-us/event-list-page/",
    component: _3f868984,
    pathToRegexpOptions: {"strict":true},
    name: "event-list-page___en-us"
  }, {
    path: "/en-us/faq/",
    component: _1fc8c4a2,
    pathToRegexpOptions: {"strict":true},
    name: "faq___en-us"
  }, {
    path: "/en-us/impressum/",
    component: _0c15c5d0,
    pathToRegexpOptions: {"strict":true},
    name: "impressum___en-us"
  }, {
    path: "/en-us/login/",
    component: _80bc9d76,
    pathToRegexpOptions: {"strict":true},
    name: "login___en-us"
  }, {
    path: "/en-us/magazine/",
    component: _6a9951cb,
    pathToRegexpOptions: {"strict":true},
    name: "magazine___en-us",
    children: [{
      path: "article/",
      component: _6d672d32,
      pathToRegexpOptions: {"strict":true},
      name: "magazine-article___en-us"
    }, {
      path: "category/",
      component: _7306bc72,
      pathToRegexpOptions: {"strict":true},
      name: "magazine-category___en-us"
    }, {
      path: "magazine-wrapper/",
      component: _457b14ce,
      pathToRegexpOptions: {"strict":true},
      name: "magazine-magazine-wrapper___en-us"
    }]
  }, {
    path: "/en-us/order-success/",
    component: _a4d5ca46,
    pathToRegexpOptions: {"strict":true},
    name: "order-success___en-us"
  }, {
    path: "/en-us/partner/",
    component: _612837a4,
    pathToRegexpOptions: {"strict":true},
    name: "partner___en-us"
  }, {
    path: "/en-us/password-reset/",
    component: _bf0c5bd8,
    pathToRegexpOptions: {"strict":true},
    name: "password-reset___en-us"
  }, {
    path: "/en-us/ping-default/",
    component: _61b66e9d,
    pathToRegexpOptions: {"strict":true},
    name: "ping-default___en-us"
  }, {
    path: "/en-us/ping-optimized/",
    component: _2d7d84fa,
    pathToRegexpOptions: {"strict":true},
    name: "ping-optimized___en-us"
  }, {
    path: "/en-us/ping/",
    component: _718c9fae,
    pathToRegexpOptions: {"strict":true},
    name: "ping___en-us"
  }, {
    path: "/en-us/root-domain/",
    component: _f68fd6d4,
    props: true,
    name: "home___en-us"
  }, {
    path: "/en-us/search/",
    component: _774c84c2,
    pathToRegexpOptions: {"strict":true},
    name: "search___en-us"
  }, {
    path: "/en-us/sitemap/",
    component: _5b366f4e,
    pathToRegexpOptions: {"strict":true},
    name: "sitemap___en-us"
  }, {
    path: "/en-us/stripe-3d-auth-complete/",
    component: _05df95ca,
    pathToRegexpOptions: {"strict":true},
    name: "stripe-3d-auth-complete___en-us"
  }, {
    path: "/en-us/sub-domain/",
    component: _1e5868d6,
    props: true,
    name: "supplier-home___en-us"
  }, {
    path: "/en-us/teamevent/",
    component: _2994dab9,
    pathToRegexpOptions: {"strict":true},
    name: "teamevent___en-us"
  }, {
    path: "/en-us/terms-and-conditions/",
    component: _2bd3f12b,
    pathToRegexpOptions: {"strict":true},
    name: "terms-and-conditions___en-us"
  }, {
    path: "/en-us/terms-supplier/",
    component: _6b129f49,
    pathToRegexpOptions: {"strict":true},
    name: "terms-supplier___en-us"
  }, {
    path: "/de-de/auth/social-login/",
    component: _8097ec2e,
    pathToRegexpOptions: {"strict":true},
    name: "auth-social-login___de-de"
  }, {
    path: "/de-de/city-category/city-category/",
    component: _05f7648a,
    pathToRegexpOptions: {"strict":true},
    name: "city-category-city-category___de-de"
  }, {
    path: "/de-de/embedded/booking/",
    component: _2061ad0b,
    pathToRegexpOptions: {"strict":true},
    name: "embedded-booking___de-de"
  }, {
    path: "/de-de/embedded/button/",
    component: _473feef0,
    pathToRegexpOptions: {"strict":true},
    name: "embedded-button___de-de"
  }, {
    path: "/de-de/login/forgot-password/",
    component: _ad015990,
    pathToRegexpOptions: {"strict":true},
    name: "login-forgot-password___de-de"
  }, {
    path: "/de-de/partner/booking-solution/",
    component: _0049130b,
    pathToRegexpOptions: {"strict":true},
    name: "partner-booking-solution___de-de"
  }, {
    path: "/de-de/partner/pricing/",
    component: _03b8ae18,
    pathToRegexpOptions: {"strict":true},
    name: "partner-pricing___de-de"
  }, {
    path: "/de-de/root-domain/giftcard/",
    component: _2d67ebf7,
    pathToRegexpOptions: {"strict":true},
    name: "root-domain-giftcard___de-de"
  }, {
    path: "/de-de/root-domain/giftcard/",
    component: _2d67ebf7,
    meta: {"contentGroup":"giftcard"},
    props: true,
    name: "gifcard-page___de-de"
  }, {
    path: "/de-de/root-domain/home/",
    component: _f68fd6d4,
    pathToRegexpOptions: {"strict":true},
    name: "root-domain-home___de-de"
  }, {
    path: "/de-de/special-giftcard/special-giftcard/",
    component: _3acdeb08,
    pathToRegexpOptions: {"strict":true},
    name: "event___de-de"
  }, {
    path: "/de-de/sub-domain/giftcard/",
    component: _cb77bf92,
    pathToRegexpOptions: {"strict":true},
    name: "sub-domain-giftcard___de-de"
  }, {
    path: "/de-de/sub-domain/giftcard/",
    component: _cb77bf92,
    props: true,
    name: "supplier-giftcard-page___de-de"
  }, {
    path: "/de-de/sub-domain/home/",
    component: _1e5868d6,
    pathToRegexpOptions: {"strict":true},
    name: "sub-domain-home___de-de"
  }, {
    path: "/de-de/t/general-event-list-page/",
    component: _0b4974c4,
    pathToRegexpOptions: {"strict":true},
    name: "t-general-event-list-page___de-de"
  }, {
    path: "/en-us/auth/social-login/",
    component: _8097ec2e,
    pathToRegexpOptions: {"strict":true},
    name: "auth-social-login___en-us"
  }, {
    path: "/en-us/city-category/city-category/",
    component: _05f7648a,
    pathToRegexpOptions: {"strict":true},
    name: "city-category-city-category___en-us"
  }, {
    path: "/en-us/embedded/booking/",
    component: _2061ad0b,
    pathToRegexpOptions: {"strict":true},
    name: "embedded-booking___en-us"
  }, {
    path: "/en-us/embedded/button/",
    component: _473feef0,
    pathToRegexpOptions: {"strict":true},
    name: "embedded-button___en-us"
  }, {
    path: "/en-us/login/forgot-password/",
    component: _ad015990,
    pathToRegexpOptions: {"strict":true},
    name: "login-forgot-password___en-us"
  }, {
    path: "/en-us/partner/booking-solution/",
    component: _0049130b,
    pathToRegexpOptions: {"strict":true},
    name: "partner-booking-solution___en-us"
  }, {
    path: "/en-us/partner/pricing/",
    component: _03b8ae18,
    pathToRegexpOptions: {"strict":true},
    name: "partner-pricing___en-us"
  }, {
    path: "/en-us/root-domain/giftcard/",
    component: _2d67ebf7,
    pathToRegexpOptions: {"strict":true},
    name: "root-domain-giftcard___en-us"
  }, {
    path: "/en-us/root-domain/giftcard/",
    component: _2d67ebf7,
    meta: {"contentGroup":"giftcard"},
    props: true,
    name: "gifcard-page___en-us"
  }, {
    path: "/en-us/root-domain/home/",
    component: _f68fd6d4,
    pathToRegexpOptions: {"strict":true},
    name: "root-domain-home___en-us"
  }, {
    path: "/en-us/special-giftcard/special-giftcard/",
    component: _3acdeb08,
    pathToRegexpOptions: {"strict":true},
    name: "event___en-us"
  }, {
    path: "/en-us/sub-domain/giftcard/",
    component: _cb77bf92,
    pathToRegexpOptions: {"strict":true},
    name: "sub-domain-giftcard___en-us"
  }, {
    path: "/en-us/sub-domain/giftcard/",
    component: _cb77bf92,
    props: true,
    name: "supplier-giftcard-page___en-us"
  }, {
    path: "/en-us/sub-domain/home/",
    component: _1e5868d6,
    pathToRegexpOptions: {"strict":true},
    name: "sub-domain-home___en-us"
  }, {
    path: "/en-us/t/general-event-list-page/",
    component: _0b4974c4,
    pathToRegexpOptions: {"strict":true},
    name: "t-general-event-list-page___en-us"
  }, {
    path: "/de-de/auth/facebook/callback/",
    component: _8097ec2e,
    meta: {"socialLoginType":"facebook"},
    name: "social-login-facebook___de-de"
  }, {
    path: "/de-de/auth/google/callback/",
    component: _8097ec2e,
    meta: {"socialLoginType":"google"},
    name: "social-login-google___de-de"
  }, {
    path: "/en-us/auth/facebook/callback/",
    component: _8097ec2e,
    meta: {"socialLoginType":"facebook"},
    name: "social-login-facebook___en-us"
  }, {
    path: "/en-us/auth/google/callback/",
    component: _8097ec2e,
    meta: {"socialLoginType":"google"},
    name: "social-login-google___en-us"
  }, {
    path: "/de-de/c/:categorySlug?/",
    component: _504ba815,
    pathToRegexpOptions: {"strict":true},
    name: "c-categorySlug___de-de"
  }, {
    path: "/de-de/city/:city?/",
    component: _c6acd3c6,
    pathToRegexpOptions: {"strict":true},
    name: "city-city___de-de"
  }, {
    path: "/de-de/e/:event?/",
    component: _54dd303c,
    pathToRegexpOptions: {"strict":true},
    name: "event___de-de",
    children: [{
      path: "",
      component: _5bded326,
      pathToRegexpOptions: {"strict":true},
      name: "e-event___de-de"
    }, {
      path: "BasicDetails/",
      component: _4bb491f7,
      pathToRegexpOptions: {"strict":true},
      name: "e-event-BasicDetails___de-de"
    }, {
      path: "EventBreadcrumbs/",
      component: _49dc5f19,
      pathToRegexpOptions: {"strict":true},
      name: "e-event-EventBreadcrumbs___de-de"
    }, {
      path: "EventIconList/",
      component: _53193dbe,
      pathToRegexpOptions: {"strict":true},
      name: "e-event-EventIconList___de-de",
      children: [{
        path: "EventIconListTable/",
        component: _09e4a31e,
        pathToRegexpOptions: {"strict":true},
        name: "e-event-EventIconList-EventIconListTable___de-de"
      }]
    }, {
      path: "Gallery/",
      component: _3caa6242,
      pathToRegexpOptions: {"strict":true},
      name: "e-event-Gallery___de-de"
    }, {
      path: "LocationAndMap/",
      component: _419eca1d,
      pathToRegexpOptions: {"strict":true},
      name: "e-event-LocationAndMap___de-de"
    }, {
      path: "PrivateEventInfo/",
      component: _641649e8,
      pathToRegexpOptions: {"strict":true},
      name: "e-event-PrivateEventInfo___de-de"
    }, {
      path: "ReviewShowcase/",
      component: _9d6d40b0,
      pathToRegexpOptions: {"strict":true},
      name: "e-event-ReviewShowcase___de-de"
    }, {
      path: "composables/useEventBreadcrumbs/",
      component: _449a4604,
      pathToRegexpOptions: {"strict":true},
      name: "e-event-composables-useEventBreadcrumbs___de-de"
    }, {
      path: "composables/useKftBuyBox/",
      component: _182f4d3c,
      pathToRegexpOptions: {"strict":true},
      name: "e-event-composables-useKftBuyBox___de-de"
    }]
  }, {
    path: "/de-de/e/:event/",
    component: _54dd303c,
    meta: {"contentGroup":"event"},
    props: true,
    pathToRegexpOptions: {"strict":true},
    name: "event-page___de-de"
  }, {
    path: "/de-de/giftcard/:event/",
    component: _3acdeb08,
    meta: {"contentGroup":"giftcard"},
    props: true,
    pathToRegexpOptions: {"strict":true},
    name: "special-giftcards___de-de"
  }, {
    path: "/de-de/giftcard/:event/",
    component: _3acdeb08,
    meta: {"contentGroup":"giftcard"},
    props: true,
    pathToRegexpOptions: {"strict":true},
    name: "special-giftcards___de-de"
  }, {
    path: "/de-de/magazine/:slug/",
    component: _457b14ce,
    meta: {"contentGroup":"magazine"},
    props: true,
    name: "magazine-pages___de-de"
  }, {
    path: "/de-de/p/:permalink?/",
    component: _8885c8bc,
    pathToRegexpOptions: {"strict":true},
    name: "p-permalink___de-de"
  }, {
    path: "/de-de/p/:permalink/",
    component: _8885c8bc,
    meta: {"contentGroup":"partner"},
    props: true,
    name: "supplier-profile___de-de"
  }, {
    path: "/de-de/p/:permalink/",
    component: _8885c8bc,
    meta: {"contentGroup":"partner"},
    props: true,
    name: "supplier-profile___de-de"
  }, {
    path: "/de-de/preview/:previewPermalink?/",
    component: _76ef3d98,
    pathToRegexpOptions: {"strict":true},
    name: "preview-previewPermalink___de-de"
  }, {
    path: "/de-de/review/:event?/",
    component: _6b77638b,
    pathToRegexpOptions: {"strict":true},
    name: "review-event___de-de"
  }, {
    path: "/de-de/t/:slug/",
    component: _0b4974c4,
    meta: {"contentGroup":"tag"},
    props: true,
    name: "general-event-list-page___de-de"
  }, {
    path: "/de-de/t/:slug/",
    component: _0b4974c4,
    meta: {"contentGroup":"tag"},
    props: true,
    name: "general-event-list-page___de-de"
  }, {
    path: "/de-de/teamevent/:city/",
    component: _0a618831,
    pathToRegexpOptions: {"strict":true},
    name: "teamevent-city___de-de"
  }, {
    path: "/de-de/teamevent/:city/",
    component: _0a618831,
    meta: {"contentGroup":"team event"},
    props: true,
    name: "team-event-page___de-de"
  }, {
    path: "/de-de/teamevent/:city/",
    component: _0a618831,
    meta: {"contentGroup":"team event"},
    props: true,
    name: "team-event-page___de-de"
  }, {
    path: "/en-us/c/:categorySlug?/",
    component: _504ba815,
    pathToRegexpOptions: {"strict":true},
    name: "c-categorySlug___en-us"
  }, {
    path: "/en-us/city/:city?/",
    component: _c6acd3c6,
    pathToRegexpOptions: {"strict":true},
    name: "city-city___en-us"
  }, {
    path: "/en-us/e/:event?/",
    component: _54dd303c,
    pathToRegexpOptions: {"strict":true},
    name: "event___en-us",
    children: [{
      path: "",
      component: _5bded326,
      pathToRegexpOptions: {"strict":true},
      name: "e-event___en-us"
    }, {
      path: "BasicDetails/",
      component: _4bb491f7,
      pathToRegexpOptions: {"strict":true},
      name: "e-event-BasicDetails___en-us"
    }, {
      path: "EventBreadcrumbs/",
      component: _49dc5f19,
      pathToRegexpOptions: {"strict":true},
      name: "e-event-EventBreadcrumbs___en-us"
    }, {
      path: "EventIconList/",
      component: _53193dbe,
      pathToRegexpOptions: {"strict":true},
      name: "e-event-EventIconList___en-us",
      children: [{
        path: "EventIconListTable/",
        component: _09e4a31e,
        pathToRegexpOptions: {"strict":true},
        name: "e-event-EventIconList-EventIconListTable___en-us"
      }]
    }, {
      path: "Gallery/",
      component: _3caa6242,
      pathToRegexpOptions: {"strict":true},
      name: "e-event-Gallery___en-us"
    }, {
      path: "LocationAndMap/",
      component: _419eca1d,
      pathToRegexpOptions: {"strict":true},
      name: "e-event-LocationAndMap___en-us"
    }, {
      path: "PrivateEventInfo/",
      component: _641649e8,
      pathToRegexpOptions: {"strict":true},
      name: "e-event-PrivateEventInfo___en-us"
    }, {
      path: "ReviewShowcase/",
      component: _9d6d40b0,
      pathToRegexpOptions: {"strict":true},
      name: "e-event-ReviewShowcase___en-us"
    }, {
      path: "composables/useEventBreadcrumbs/",
      component: _449a4604,
      pathToRegexpOptions: {"strict":true},
      name: "e-event-composables-useEventBreadcrumbs___en-us"
    }, {
      path: "composables/useKftBuyBox/",
      component: _182f4d3c,
      pathToRegexpOptions: {"strict":true},
      name: "e-event-composables-useKftBuyBox___en-us"
    }]
  }, {
    path: "/en-us/e/:event/",
    component: _54dd303c,
    meta: {"contentGroup":"event"},
    props: true,
    pathToRegexpOptions: {"strict":true},
    name: "event-page___en-us"
  }, {
    path: "/en-us/giftcard/:event/",
    component: _3acdeb08,
    meta: {"contentGroup":"giftcard"},
    props: true,
    pathToRegexpOptions: {"strict":true},
    name: "special-giftcards___en-us"
  }, {
    path: "/en-us/giftcard/:event/",
    component: _3acdeb08,
    meta: {"contentGroup":"giftcard"},
    props: true,
    pathToRegexpOptions: {"strict":true},
    name: "special-giftcards___en-us"
  }, {
    path: "/en-us/magazine/:slug/",
    component: _457b14ce,
    meta: {"contentGroup":"magazine"},
    props: true,
    name: "magazine-pages___en-us"
  }, {
    path: "/en-us/p/:permalink?/",
    component: _8885c8bc,
    pathToRegexpOptions: {"strict":true},
    name: "p-permalink___en-us"
  }, {
    path: "/en-us/p/:permalink/",
    component: _8885c8bc,
    meta: {"contentGroup":"partner"},
    props: true,
    name: "supplier-profile___en-us"
  }, {
    path: "/en-us/p/:permalink/",
    component: _8885c8bc,
    meta: {"contentGroup":"partner"},
    props: true,
    name: "supplier-profile___en-us"
  }, {
    path: "/en-us/preview/:previewPermalink?/",
    component: _76ef3d98,
    pathToRegexpOptions: {"strict":true},
    name: "preview-previewPermalink___en-us"
  }, {
    path: "/en-us/review/:event?/",
    component: _6b77638b,
    pathToRegexpOptions: {"strict":true},
    name: "review-event___en-us"
  }, {
    path: "/en-us/t/:slug/",
    component: _0b4974c4,
    meta: {"contentGroup":"tag"},
    props: true,
    name: "general-event-list-page___en-us"
  }, {
    path: "/en-us/t/:slug/",
    component: _0b4974c4,
    meta: {"contentGroup":"tag"},
    props: true,
    name: "general-event-list-page___en-us"
  }, {
    path: "/en-us/teamevent/:city/",
    component: _0a618831,
    pathToRegexpOptions: {"strict":true},
    name: "teamevent-city___en-us"
  }, {
    path: "/en-us/teamevent/:city/",
    component: _0a618831,
    meta: {"contentGroup":"team event"},
    props: true,
    name: "team-event-page___en-us"
  }, {
    path: "/en-us/teamevent/:city/",
    component: _0a618831,
    meta: {"contentGroup":"team event"},
    props: true,
    name: "team-event-page___en-us"
  }, {
    path: "/de-de/:citySlug/",
    component: _c6acd3c6,
    meta: {"contentGroup":"city"},
    props: true,
    name: "city-page___de-de"
  }, {
    path: "/de-de/:citySlug/",
    component: _c6acd3c6,
    meta: {"contentGroup":"city"},
    props: true,
    name: "city-page___de-de"
  }, {
    path: "/en-us/:citySlug/",
    component: _c6acd3c6,
    meta: {"contentGroup":"city"},
    props: true,
    name: "city-page___en-us"
  }, {
    path: "/en-us/:citySlug/",
    component: _c6acd3c6,
    meta: {"contentGroup":"city"},
    props: true,
    name: "city-page___en-us"
  }, {
    path: "/de-de/:citySlug/:categorySlug/",
    component: _05f7648a,
    meta: {"contentGroup":"city category"},
    props: true,
    name: "city-categories-page___de-de"
  }, {
    path: "/de-de/:citySlug/:categorySlug/",
    component: _05f7648a,
    meta: {"contentGroup":"city category"},
    props: true,
    name: "city-categories-page___de-de"
  }, {
    path: "/en-us/:citySlug/:categorySlug/",
    component: _05f7648a,
    meta: {"contentGroup":"city category"},
    props: true,
    name: "city-categories-page___en-us"
  }, {
    path: "/en-us/:citySlug/:categorySlug/",
    component: _05f7648a,
    meta: {"contentGroup":"city category"},
    props: true,
    name: "city-categories-page___en-us"
  }],

  fallback: false
}

export function createRouter (ssrContext, config) {
  const base = (config._app && config._app.basePath) || routerOptions.base
  const router = new Router({ ...routerOptions, base  })

  // TODO: remove in Nuxt 3
  const originalPush = router.push
  router.push = function push (location, onComplete = emptyFn, onAbort) {
    return originalPush.call(this, location, onComplete, onAbort)
  }

  const resolve = router.resolve.bind(router)
  router.resolve = (to, current, append) => {
    if (typeof to === 'string') {
      to = normalizeURL(to)
    }
    return resolve(to, current, append)
  }

  return router
}
