/* eslint-disable camelcase */
import { AgnosticCoupon, AgnosticTotals } from "@konfetti-core/core";
import {
  Cart,
  CartItem,
  GiftcardCartItem,
  Coupon,
  EventAddOn,
  EventDateType,
  GTMItem,
  OrderItem,
  Price,
  ShippingCost,
} from "../types";
import {
  getEventAddOnUnitPriceAmount,
  getEventAddOnId,
  getEventAddOnName,
  getEventAddOnPriceType,
} from "./eventAddonGetters";

interface IGiftboxPrice {
  amount: number;
  currency: string;
  formatted: string;
}

export const fnGetCartItemsLength = (cart: Cart): number => {
  return cart.items.length;
};

export const getGiftboxPrice = (cart: Cart): IGiftboxPrice => {
  let returnObj: IGiftboxPrice = {
    amount: 100,
    currency: "",
    formatted: "",
  };

  if (cart?.giftboxPrice) {
    returnObj = { ...cart?.giftboxPrice };
  }

  return {
    ...returnObj,
    amount: Number(returnObj.amount) / 100,
  };
};

export const getGiftboxPriceAmount = (cart: Cart): string =>
  cart?.giftboxPrice?.amount ?? 0;

export const getShippingCost = (cart: Cart): ShippingCost => {
  return cart?.shippingCost;
};

export const getIsValueBasedGiftcard = (item: CartItem): boolean => {
  return item.type === "GIFT_CARD" && !item?.eventDescriptionId;
};

export const getIsNonValueBasedGiftcard = (item: CartItem): boolean => {
  return item.type === "GIFT_CARD" && Boolean(item?.eventDescriptionId);
};

export const getGiftboxTotalPriceAmount = (cart: Cart): number => {
  const baseGiftboxCost = getGiftboxPrice(cart);

  const valueBasedGiftcard = cart.items.find((item) =>
    getIsValueBasedGiftcard(item),
  );

  let giftboxTotalPrice = 0;

  cart.items.forEach((item) => {
    const isValueBasedGiftcard = getIsValueBasedGiftcard(item);
    const isNonValueBasedGiftcard = getIsNonValueBasedGiftcard(item);

    if (isNonValueBasedGiftcard) {
      giftboxTotalPrice += Number(baseGiftboxCost?.amount || 0);
    } else if (isValueBasedGiftcard) {
      giftboxTotalPrice +=
        Number(baseGiftboxCost?.amount || 0) * getItemQty(valueBasedGiftcard);
    }
  });

  return giftboxTotalPrice;
};

export const getShippingPrice = (cart: Cart): Price => {
  return getShippingCost(cart)?.value;
};

export const getShippingPriceAmount = (cart: Cart): number => {
  const shippingCost = getShippingCost(cart);
  return shippingCost ? parseFloat(shippingCost.value?.amount) / 100 : 0;
};

export const getTotalItems = (cart: Cart): number => {
  return cart?.items?.length || 0;
};

// eslint-disable-next-line @typescript-eslint/no-unused-vars
export const getFormattedPrice = (price: number): string => {
  return "";
};

// eslint-disable-next-line @typescript-eslint/no-unused-vars
export const getCoupons = (cart: Cart): AgnosticCoupon[] => {
  return [];
};

export const getDiscount = (coupon: Coupon): any => {
  return coupon?.discount || 0;
};

/** @deprecated
 * This function does not change the formatting in relation to the user locale
 * Use fnGetDateLabelHtml
 * */
export const getFormattedDate = (item: CartItem): string => {
  return new Date(item.date).toLocaleDateString();
};

export const isGiftboxEnabled = (cart: Cart): boolean => {
  return cart?.isGiftboxEnabled || false;
};

export const getItems = (cart: Cart): CartItem[] => {
  return cart?.items || [];
};

export const getItemPermalink = (item: CartItem): string => {
  return item.permalink || "";
};

export const getItemId = (item: CartItem): string => {
  return item.id || "";
};

export const getEventDescriptionId = (item: CartItem): string => {
  return item.eventDescriptionId || "";
};

export const getItemIdForGtm = (item: CartItem): string => {
  // for GA we want the event description ID's
  if (item.type === "GIFT_CARD" && !getEventDescriptionId(item)) {
    const price = parseInt(item.price.amount) / 100;
    return "giftcard-" + price;
  }
  return getEventDescriptionId(item) || "";
};

export const isGiftcard = (item: CartItem): boolean => {
  return item.type === "GIFT_CARD";
};

export const hasGiftcard = (cart: Cart): boolean => {
  const length = cart.items.length;

  for (let i = 0; i < length; i++) {
    if (cart.items[i].type === "GIFT_CARD") {
      return true;
    }
  }

  return false;
};

export const getAllAddonsForOrder = (
  cart: Cart,
): { quantity: number; addon: string; parent: string; type: string }[] => {
  return cart.items
    .filter((item) => item.addOn)
    .map((item) => ({
      addon: item.addOn.id,
      type: "ADD_ON_SETTING",
      quantity:
        getEventAddOnPriceType(item?.addOn) === "PER_SESSION"
          ? 1
          : item.quantity,
      parent: item.id,
    }));
};

export const getItemsForOrder = (cart: Cart): OrderItem[] => {
  const length = cart?.items?.length;
  const items = [];

  for (let i = 0; i < length; i++) {
    const item = cart.items[i];

    if (item.type === "EVENT") {
      items.push({
        type: "EVENT",
        product: item?.id,
        quantity: item?.quantity,
        private: item?.private,
        price: (parseInt(item?.price?.amount) / 100).toFixed(2),
      });
    }

    if (item.type === "GIFT_CARD") {
      const supplierId = item?.supplierId;

      const giftcardAddon = getGiftcardAddon(item);
      items.push({
        type: "GIFT_CARD",
        event: item?.id,
        quantity: item?.quantity,
        value: item?.price?.amount,
        ...(supplierId && { supplier_id: item?.supplierId }),
        // isGiftboxEnabled here for Legacy fallback
        // checking if giftcardAddon is undefined because if so,
        // that will be legacy
        ship:
          (Boolean(giftcardAddon) && giftcardAddon !== "PDF") ||
          isGiftboxEnabled(cart),
        ...(getGiftcardAddon(item) !== "PDF" && {
          ship_type: getGiftcardAddon(item),
        }),
      });
    }
  }

  const addons = getAllAddonsForOrder(cart);
  items.push(...addons);

  return items;
};

export const getItemsForGtm = (cart: Cart): GTMItem[] => {
  const length = cart.items.length;
  const items = [];
  let index = 0;
  for (let i = 0; i < length; i++) {
    const item = cart.items[i];
    const price = getUnitItemPrice(item);

    if (item.type === "GIFT_CARD") {
      items.push({
        index,
        item_id: getItemIdForGtm(item),
        item_name: item.title,
        item_category: getItemMainCategory(item),
        item_variant: getItemVariantForGtm(item),
        item_brand: item.supplier || "konfetti",
        quantity: item.quantity,
        price: price?.toFixed(2) || price,
        ship: getGiftcardAddon(item) !== "PDF",
        shipType: getGiftcardAddon(item) !== "PDF" && getGiftcardAddon(item),
      });
    } else {
      items.push({
        index,
        item_id: getItemIdForGtm(item),
        item_name: item.title,
        item_category: getItemMainCategory(item),
        item_variant: getItemVariantForGtm(item),
        item_brand: item.supplier,
        quantity: item.quantity,
        price: price?.toFixed(2) || price,
      });
    }

    const addon = getItemAddon(item);

    if (addon) {
      const addonPrice = Number(
        (getEventAddOnUnitPriceAmount(addon) || 0) / 100,
      );
      index = index + 1;
      items.push({
        index,
        item_id: `addon-${getEventAddOnId(addon)}` || "",
        item_name: getEventAddOnName(addon) || "",
        item_category: "ADD_ON",
        item_variant: "ADD_ON",
        quantity: item.quantity,
        price: addonPrice?.toFixed(2) || addonPrice,
      });
    }
    index = index + 1;
  }

  return items;
};

export const getItemsForAlgoliaInsights = (cart: Cart) => {
  const objectIDs = cart?.items?.map(
    (item) => `EventDescription::${item?.eventDescriptionId}`,
  );
  const objectData = cart?.items?.map((item) => ({
    queryID: item?.queryID,
    price: getUnitItemPrice(item)?.toFixed(2),
    quantity: item?.quantity ?? 1,
  }));
  return { objectIDs, objectData };
};

export const getItemMainCategory = (item: CartItem): string => {
  if (item.type === "GIFT_CARD") {
    return "giftcard";
  }
  return item.category || "";
};

export const getItemVariantForGtm = (item: CartItem): string => {
  if (item.attendanceMode?.toUpperCase() === "NATIONWIDE") {
    return "KIT";
  }

  if (item.type === "GIFT_CARD") {
    return item.type;
  }

  if (item.private === true) {
    return "PRIVATE_EVENT";
  }
  // default will be "EVENT"
  return item.type;
};

export const getCartHasItem = (cart: Cart, id: string): boolean => {
  return cart.items.some((item) => item.id === id);
};

export const getItemName = (item: CartItem): string => {
  return item.title || "";
};

export const getItemImage = (item: CartItem): string => {
  return item.thumbnail || "";
};

export const getItemPrice = (item: CartItem): number => {
  const qty =
    item.private && item.privateMinTickets > item.quantity
      ? item.privateMinTickets
      : item.quantity;

  return (parseFloat(item.price.amount) * qty) / 100;
};

export const getUnitItemPrice = (item: CartItem): number => {
  const privateMinTickets = item?.privateMinTickets;
  const price = parseFloat(item?.price?.amount);
  const qty = item?.quantity;

  if (item?.dateTypeToBeBooked === EventDateType.PRIVATE) {
    if (privateMinTickets > qty) {
      return (price * privateMinTickets) / qty / 100;
    }
  }

  return price / 100;
};

export const getItemPriceAmount = (item: CartItem): number => {
  return Number(item.price.amount);
};

export const getItemQty = (item: CartItem): number => {
  return item.quantity || 1;
};

export const getAvailableStock = (item: CartItem): number => {
  if (item.type === "GIFT_CARD") {
    return 999999;
  }

  if (item.private) {
    return item?.privateMaxTickets || item.availableTicketsQuantity || 1;
  }

  return item.availableTicketsQuantity || 1;
};

export const isPrivate = (item: CartItem): boolean => {
  return item.private || false;
};

const getAddonTotalAmountByCartItem = (cartItem: CartItem) => {
  const qty =
    getEventAddOnPriceType(cartGetters.getItemAddon(cartItem)) === "PER_SESSION"
      ? 1
      : cartItem.quantity;
  return getEventAddOnUnitPriceAmount(cartGetters.getItemAddon(cartItem)) * qty;
};

export const getCartTotalPrice = (cart: Cart, isTotal: boolean): number => {
  if (!cart || !cart.items) {
    return 0;
  }

  let total = 0;
  let payedGiftcardsTotal = 0;
  for (let i = 0; i < cart.items.length; i++) {
    const item = cart.items[i];

    total += getItemPrice(item);

    if (item.addOn) {
      total += Number(getAddonTotalAmountByCartItem(item)) / 100;
    }

    if (isGiftcard(item)) {
      total += getGiftcardItemAddonTotalPrice(item, isGiftboxEnabled(cart));
      payedGiftcardsTotal += getGiftcardItemAddonTotalPrice(
        item,
        isGiftboxEnabled(cart),
      );
    }
  }

  if (isTotal) {
    total += getShippingPriceAmount(cart);
  }

  return total;
};

export const getTotals = (cart: Cart): AgnosticTotals => {
  return {
    total: getCartTotalPrice(cart, true),
    subtotal: getCartTotalPrice(cart, false),
    special: null,
  };
};

export const getItemAddon = (
  cartItem: CartItem,
): typeof cartItem.addOn | undefined => {
  return cartItem?.addOn;
};

export const getItemAddonThumb = (cartItem: CartItem): string | undefined => {
  return getItemAddon(cartItem)?.media?.data?.thumb;
};

export const getItemAddonQuantity = (cartItem: CartItem): number => {
  // addon quantity is not yet implented but will be in the future,
  // for now we just return the cartItem quantity
  return getItemAddon(cartItem)?.quantity ?? getItemQty(cartItem);
};

export const isValueBasedGiftcard = (item: CartItem): boolean => {
  return item?.type === "GIFT_CARD" && !item?.id && !item.eventDescriptionId;
};

// get label of selected giftcard addon
export const getGiftcardAddon = (
  cartItem: GiftcardCartItem,
): string | undefined => {
  return cartItem?.giftcardAddon;
};

// calculate giftcard addon price
export const getGiftcardItemAddonTotalPrice = (
  item: GiftcardCartItem,
  isGiftboxEnabled = false,
): number => {
  if (!isGiftcard(item)) return 0;
  if (getGiftcardItemAddonPrice(item, isGiftboxEnabled) === 0) return 0;

  const amount = getGiftcardItemAddonPrice(item, isGiftboxEnabled) / 100;
  return isValueBasedGiftcard(item) ? amount * item.quantity : amount;
};

// get price of giftcard addon using giftcard label
export const getGiftcardItemAddonPrice = (
  item: GiftcardCartItem,
  isGiftboxEnabled = false,
): number => {
  const addon = getGiftcardAddon(item);
  if (!addon && !isGiftboxEnabled) return 0; // LEGACY FALLBACK
  if (!addon && isGiftboxEnabled) return getGiftcardListAddon[0]?.price; // LEGACY FALLBACK

  return getGiftcardListAddon.find((item) => item.id === addon)?.price;
};

export const getNeedsMajority = (cart: Cart) =>
  cart?.items?.some((item) => item?.needsMajority);

export const hasShippingCosts = (cart: Cart): boolean => {
  return cart.items.some((item: CartItem) => item?.hasShippingCosts);
};

// map of available addons with legacy option
const getGiftcardListAddon = [
  {
    id: "LEGACY",
    price: 295,
  },
  {
    id: "PDF",
    price: 0,
  },
  {
    id: "CARD",
    price: 295,
  },
  {
    id: "BOX",
    price: 495,
  },
];

export const getItemOriginalPrice = (item: CartItem): number => {
  const qty =
    item.private && item.privateMinTickets > item.quantity
      ? item.privateMinTickets
      : item.quantity;

  const amount = item?.originalPrice?.amount || "0";

  return (parseFloat(amount) * qty) / 100;
};

export const cartGetters = {
  getTotals,
  getShippingPrice,
  getItems,
  getItemName,
  getItemImage,
  getItemPrice,
  getItemId,
  getItemQty,
  getFormattedPrice,
  getTotalItems,
  getCoupons,
  getDiscount,
  getAvailableStock,
  getCartHasItem,
  getItemPermalink,
  getCartTotalPrice,
  getItemsForOrder,
  getFormattedDate,
  isGiftboxEnabled,
  getGiftboxPrice,
  getGiftboxPriceAmount,
  isGiftcard,
  hasGiftcard,
  getItemsForGtm,
  getItemMainCategory,
  fnGetCartItemsLength,
  isPrivate,
  getUnitItemPrice,
  getItemPriceAmount,
  getItemVariantForGtm,
  getItemIdForGtm,
  getEventDescriptionId,
  getItemAddon,
  getItemAddonThumb,
  getItemAddonQuantity,
  getAllAddonsForOrder,
  getAddonTotalAmountByCartItem,
  getGiftboxTotalPriceAmount,
  isValueBasedGiftcard,
  getGiftcardAddon,
  getGiftcardItemAddonTotalPrice,
  getGiftcardItemAddonPrice,
  getGiftcardListAddon,
  hasShippingCosts,
  getNeedsMajority,
  getItemOriginalPrice,
  getItemsForAlgoliaInsights,
};
